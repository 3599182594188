.loaderMessage,
.loaderMessage:before,
.loaderMessage:after {
  width: 7px; /* update this to control the size */
  aspect-ratio: .5;
  display: grid;
  background: radial-gradient(#fff 68%,#0000 72%) center/100% 50% no-repeat;
  animation: load 1.2s infinite linear calc(var(--_s,0)*.4s);
  transform: translate(calc(var(--_s,0)*150%));
  color: white;
}
.loaderMessage:before,
.loaderMessage:after {
  content: "";
  grid-area: 1/1;
}
.loaderMessage:before {--_s: -1}
.loaderMessage:after  {--_s:  1}

@keyframes load {
    20% {background-position: top   }
    40% {background-position: bottom}
    60% {background-position: center}
  }